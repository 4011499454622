@use '../../styles/' as *;

.bubble-tab-container {
    z-index: 1;
    position: fixed;
    top: 5;
    left: 0;
}

.bubble-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgba(128, 128, 128, 0.206);
    transition: all 1.5s ease-in-out;
    height: auto;
}

.bubble-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    background-color: $primary-500;
}

.bubble-button {
    border: 1px solid $primary-300;
    margin: 0, 1rem;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.active {
    background-color: $primary-200;
    color: $secondary-200;
    height: 3.5rem;
    width: 3.5rem;
}

.inactive {
    background-color: $secondary-400;
    color: $primary-400;
}

.open-close-button {
    color: $primary-200;
    border: 1px solid $primary-300;
    margin: 0, 1rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}

.bubble-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    height: 4rem;
    background-color: rgba(128, 128, 128, 0.434);
    position: fixed;
    top: 4rem;
    width: 100%;
    transition: all .5s ease-in-out;
}

.hide {
    height: 0;
    padding: 0;
}