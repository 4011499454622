@use "../../styles/" as *;

.search-entity-section {
  display: flex;
  padding-top: 4.5rem;
  background-color: $surface-100;
  z-index: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5rem;
}

.tab-content-inactive {
  display: none;
}

.tab-content-active {
  display: block;
}

.city-data {
  margin-left: 2rem;
  margin-right: 2rem;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-size: cover;
  background-image: url("../../styles/images/city-bg-texture.webp");
}

.city-report-section {
  display: flex;
  flex-direction: column;
  background-color: $surface-100;
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.explore-city-heading {
  color: $text-700;
  font-weight: 700;
}

.city-reports {
  display: flex;
}

.explore-city-content {
  font-weight: 400;
  font-family: Merriweather Sans;
  font-size: 16px;
  line-height: 28px;
  color: $text-400;
}

.city-entity-search-header {
  margin-top: 0;
  color: $text-700;
  font-family: $roboto;
  font-weight: 700;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}

.city-entity-search-subheader {
  font-family: $merriweather;
  color: $text-400;
  padding-bottom: 1rem;
  font-weight: normal;
  width: 80%;
  white-space: pre-line;
}

.city-entity-search-menu-header {
  font-family: $merriweather;
  text-transform: uppercase;
  color: $text-500;
  padding-bottom: 0.75rem;
}

.city {
  padding: 4rem 4rem 4rem 4rem;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;

  &__details {
    line-height: 1.5rem;
    font-family: $merriweather;
    flex: 1;
    font-size: 1.125rem;
    width: 85%;
  }
  &__heading {
    font-family: $roboto;
    margin-bottom: 1rem;
  }
  &__picture {
    & img {
      border-radius: 0.625rem;
      max-height: 20rem;
      width: 33.875rem;
      height: 20rem;
      object-fit: cover;
      object-position: center;
    }
  }
}

.cityInformation {
  &__tabsContainer {
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    gap: 10px;
    background-color: #ededeb;
    padding-left: 2rem;
    background-size: cover;
    background-position: center;
    background-image: url("../../styles/images/city-bg-texture.webp");
  }

  &__tab {
    color: $text-100;
    background-color: $primary-neutral-400;
    padding: 8px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    & h6 {
      font-size: 1.125rem;
    }

    &--selected {
      color: $text-100;
      background-color: $primary-500;
    }
  }

  &__detailContainer {
    padding-left: 2rem;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
    background-color: $primary-500;
    margin: 0 2rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__heading {
    color: $text-100;
  }

  &__details {
    line-height: 1.6;
    color: $text-100;
  }
}

.search-input-container {
  flex: 1;
}

.search-entity-text {
  flex: 1;
}

.cityReportContainer {
  padding-bottom: 1rem;
  margin: 0 2rem;
  background-color: $primary-500;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cityReportTitle {
  color: $text-100;
}

.cityReportDesc {
  font-weight: 400;
  line-height: 1.75rem;
  font-size: clamp(0.875rem, 8vw, 1.125rem);
  color: $text-100;
}

.downloadTextContainer .download-report-data {
  color: white;
  font-size: 11px;
}

.downloadTextContainer {
  padding-top: 1rem;
}

.filter-table-city {
  overflow-y: auto;
  width: auto;
}

.search-container {
  margin-bottom: 3rem;
  max-width: 24rem;
}

@media screen and (max-width: 1200px) {
  .city {
    &__picture {
      & img {
        object-position: center;
        object-fit: cover;
        border-radius: 1rem;
        height: 20rem;
        max-width: 100%;
      }
    }
  }

  .city-entity-search-subheader {
    padding-bottom: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .city-entity-search-subheader {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }

  .search-entity-section {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 4.5rem;
  }

  .explore-city-data {
    padding-top: 2rem;
    padding-left: 2rem;
  }
}

@media screen and (max-width: $desktop-min) {
  .city {
    flex-direction: column;
    padding: 1rem 4rem;
    &__picture {
      margin: 2rem 0;

      & img {
        object-position: center;
        object-fit: cover;
        border-radius: 1rem;
        border-radius: 0.75rem;
      }
    }
  }

  .cityInformation {
    &__tabsContainer {
      display: none;
    }
    &__detailContainer {
      padding-left: 2rem;
    }
  }

  .cityReportTitle {
    display: none;
  }

  .cityReportContainer {
    padding-top: 2rem;
  }

  .cityReportDesc {
    font-weight: 300;
    font-size: 14px;
    padding-right: 0;
  }
}

@media screen and (max-width: $tablet-min) {
  .search-entity-section {
    flex-direction: column;
    padding-bottom: 0rem;
  }

  .city {
    padding: 2rem 1rem;

    &__heading {
      padding-bottom: 10px;
      border-bottom: 1px solid $primary-500;
      font-family: $merriweather;
      color: $text-700;
      margin-bottom: 1rem;
      font-weight: 400;
    }
    &__details {
      font-size: 0.875rem;
      width: 100%;
    }
  }

  .cityInformation {
    &__tabsContainer {
      display: none;
    }
    &__detailContainer {
      padding-left: 2rem;
      flex-direction: column;
    }
    &__report {
      flex-basis: auto;
    }
  }

  .city-entity-search-subheader {
    width: 100%;
  }

  .explore-city-data {
    padding-top: 3rem;
    padding-left: 3rem;
  }
}
