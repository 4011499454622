@use "../../styles/" as *;

.input-box {
  background-color: $surface-300;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.selected-container {
  height: 3.75rem;
  min-width: 20rem;
  background-color: $surface-300;
  display: flex;
  box-shadow: $elevation-2;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.7rem;
  cursor: pointer;
}

.ld-selected-selected-container {
  height: 3.75rem;
  width: 30.5rem;
  background-color: $surface-300;
  display: flex;
  box-shadow: $elevation-2;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.7rem;
  cursor: pointer;
}

.selected-icon-text {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-family: $merriweather;
  font-weight: 300;
  font-size: 1.2rem;

  svg {
    width: 25px;
    height: 25px;
  }
}

.input {
  border: 0;
  background-color: $surface-300;
  color: $text-400;
  font-family: $merriweather;
  height: 90%;
  font-size: 20px;
  height: 3rem;
  width: 100%;
}

.selects-container {
  display: flex;
  height: 3rem;
  background-color: $graphic-100;
  min-width: 20rem;
  padding-left: 0.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 5;
}

.local-district-selects-container {
  display: flex;
  height: 3rem;
  background-color: $graphic-100;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  z-index: 5;
  box-sizing: border-box;
  width: 100%;
}

.select-subtext {
  font-size: 0.7rem;
  border: 2px solid #82817f;
  padding: 0.25rem;
  border-radius: 1rem;
  margin-right: 1rem;
  color: $text-300;
  background-color: $surface-100;
}

.entity-options {
  display: flex;
  height: 2.5rem;
  background-color: #ffffff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.entity-icon {
  padding-left: 1rem;
}

.selects-container-no-entity {
  display: flex;
  height: 3rem;
  background-color: $graphic-100;
  min-width: 20rem;
  padding-left: 0.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  position: relative;
  z-index: 5;
}

.selects-container-no-entity:hover {
  display: flex;
  height: 3rem;
  min-width: 20rem;
  padding-left: 0.7rem;
  padding-top: 0.5rem;
  align-items: center;
  background-color: $surface-300;
}

.chevron-down {
  padding-right: 1rem;
  color: $text-500;
}

.filter-container {
  color: $text-500;
  font-family: $merriweather;
  position: relative;
  z-index: 5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 2px solid $primary-400;
  max-width: 21rem;
}

.local-district-filter-container {
  color: $text-500;
  font-family: $merriweather;
  position: relative;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 2px solid $primary-400;
  width: fit-content;
}

.select-subtext {
  border: 1px sold black;
}

.selects-container:hover {
  display: flex;
  height: 3rem;
  min-width: 20rem;
  padding-left: 0.7rem;
  padding-top: 0.5rem;
  align-items: center;
  background-color: $surface-300;
  cursor: pointer;
}

.selects-container-selected {
  display: flex;
  height: 3rem;
  width: 20rem;
  padding-left: 0.7rem;
  padding-top: 0.5rem;
  align-items: center;
  background-color: $surface-300;
  cursor: pointer;
}

.select-box {
  width: 20.5rem;
  z-index: 2;
  position: absolute;
  box-shadow: $elevation-2;
}

.ld-select-box {
  width: 31.5rem;
  z-index: 2;
  position: absolute;
  box-shadow: $elevation-2;
}

.list-container {
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.select-text {
  padding-left: 1rem;
}

.select-text-no-entity {
  padding-left: 1rem;
  color: $text-200;
}

.not-selected-icon {
  width: 5rem;
}

#wrap {
  width: 100%;
}

.no-results-text {
  padding-left: 1rem;
  color: $text-300;
}

@media screen and (max-width: 760px) {
  .selected-icon-text {
    font-size: 1rem;
  }

  .icon-entity {
    width: 1rem;
  }

  .icon-entity-selects {
    width: 1.5rem;
  }

  .select-text {
    font-size: 0.7rem;
  }

  .select-text-no-entity {
    font-size: 1rem;
  }

  .selected-container {
    min-width: 10rem;
  }

  .ld-selected-selected-container {
    width: 23rem;
  }

  .input {
    width: 10rem;
    font-size: 1rem;
  }

  .list-container {
    max-height: 10rem;
  }

  .selects-container {
    min-width: 10rem;
  }

  .selects-container-no-entity {
    width: 10rem;
  }

  .select-box {
    width: 10.5rem;
  }
  .ld-select-box {
    width: 24rem;
  }
}

@media screen and (max-width: 400px) {
  .icon-entity {
    width: 0.7rem;
  }

  .icon-entity-selects {
    width: 0.8rem;
  }

  .select-text {
    padding-left: 0.2rem;
    font-size: 0.8rem;
  }

  .select-text-no-entity {
    font-size: 0.7rem;
  }

  .selected-container {
    min-width: 15rem;
  }
  .ld-selected-selected-container {
    width: 20.5rem;
  }

  .input-box {
    font-size: 14px;
  }

  .input {
    width: 5rem;
    font-size: 0.8rem;
  }

  .list-container {
    max-height: 7rem;
  }

  .selects-container-no-entity {
    width: 7rem;
  }

  .select-box {
    width: 7.5rem;
  }

  .ld-select-box {
    width: 21rem;
  }

  .entity-icon {
    padding-left: 1rem;
    width: 18px;
  }
}

@media screen and (max-width: 320px) {
  .ld-select-box {
    width: 18.5rem;
  }

  .ld-selected-selected-container {
    width: 17.5rem;
  }

  .select-text {
    padding-left: 0.2rem;
    font-size: 0.6rem;
  }

  .select-subtext {
    font-size: 0.5rem;
    border: 2px solid #82817f;
    padding: 0.25rem;
    border-radius: 1rem;
    margin-right: 1rem;
    color: #82817f;
    background-color: #fcfaf5;
  }

  .entity-icon {
    padding-left: 1rem;
    width: 18px;
  }

  .selected-icon-text {
    font-size: 0.8rem;
  }
}
