@use "../../../styles/" as *;
.additional-reports-container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding-top: 4.62rem;
  padding-bottom: 4.62rem;

  .more-high-level-cards {
    padding-bottom: 2rem;
  }
  .report-data-section {
    background-color: #00000000;
  }
  .title-row {
    font-family: Roboto Slab;
    display: flex;
    align-items: center;
    color: $text-700;
    .icon {
      width: 2.25rem;
      height: 2.25rem;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../styles/images/SCOMenuTextureGraphic.webp");
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    z-index: -1;
  }
}
