@use "../../styles/" as *;

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 1rem;
  width: 100vw;
  background-color: $secondary-400;
  transition: width 1s ease-in-out;
  z-index: 99;
}
.idle {
  width: 0%;
}

.starting {
  width: 25%;
}

.in-progress {
  width: 60%;
}

.finished {
  width: 100%;
}
