@use "../../styles/" as *;

.explore-section {
  padding: 1rem;
  margin: 1rem;
  width: 42rem;
  height: fit-content;
  align-items: center;
  gap: 1rem;
}

.landing-page-info-bubbles {
  display: flex;
  flex-direction: row;
  min-width: 90%;
  justify-content: space-between;
}

.county-text {
  width: 85%;
}

.state-agency-background-image {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 25%;
    background: linear-gradient(
      to bottom,
      rgb(252, 250, 245) 0%,
      rgba(252, 250, 245, 0.94) 10%,
      transparent
    );
  }
}
