@use "../../styles/" as *;

.entity-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: $elevation-1;
  border-radius: 10px;
  font-family: $merriweather;
  padding: 0.9rem;
  margin: 0.1rem;
  margin-bottom: 0.5rem;
  min-width: 65rem;

  &.no-entity {
    justify-content: center;
  }

  .entity-icon-name-container {
    display: flex;
    align-items: center;
  }

  .entity-name {
    padding-left: 1rem;
    max-width: 16rem;
    min-width: 16rem;
    font-family: $merriweather;
    text-transform: uppercase;
    line-height: 1.5rem;
    font-size: 1.25rem;
    font-weight: normal;
    margin: 0;
  }

  .entity-icon {
    min-width: 1.3rem;
    max-width: 1.3rem;
  }

  .data-type-info-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 4rem;

    &.no-data-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .col-3 {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
    }

    .col-2 {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
    }
  }
  

  .data-type {
    color: $text-500;
    text-align: end;

  }

  .data-not-found {
    color: $text-500;
    min-height: 2.7rem;
    padding-top: 0.8rem;
  }

  .data-info-values {
    display: flex;
    flex-direction: column;
    font-family: $merriweather;
    color: $text-400;
    padding-left: 1rem;
    max-width: 9rem;
  }

  .link-row {
    display: flex;
  }

  .no-budget-space {
    width: 8rem;
  }  

  .download-link-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-left: 1rem;
    cursor: pointer;
    min-width: 9rem;

    &__row {
      display: flex;
      gap: 0.5rem;
    }
  }

  .download-link {
    text-decoration-line: underline;
    color: $text-700;
    cursor: pointer;
    gap: 0.5rem;
    text-wrap: wrap;
  }

  @media screen and (max-width: 1240px) {
    .data-info-values {
      font-size: 0.875rem !important;
      max-width: 7rem;
    }

    .download-link {
      font-size: 0.875rem !important;
    }

    .data-type {
      padding-left: 1rem;
      font-size: 0.875rem !important;
    }

    .entity-name {
      font-size: 1rem !important;
    }

    .entity-card-container {
      padding: 0.6rem;
      min-width: 50rem;
    }

    .entity-icon-name-container {
      padding-left: 0.5rem;
    }
  }
}
