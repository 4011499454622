@use "../../styles/" as *;

.pr-content-tags-container {
  display: flex;
  flex-direction: column;
  .pr-scroll-text {
    color: $primary-500;
    font-family: "Merriweather Sans";
    margin-top: 3.38rem;
    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  .content-tags {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (max-width: 720px) {
      display: none;
    }
    .content-tag {
      display: flex;
      width: 21.0625rem;
      border: 0;
      padding: 13px 0.875rem;
      align-items: flex-start;
      gap: 0.75rem;
      border-radius: 0.375rem;
      background: rgba(62, 94, 148, 0.25);
      box-shadow: 0px 4px 4px 0px #00000040;
      cursor: pointer;

        &:hover {
          background: rgba(62, 93, 148, 0.4);
        }
      .icon {
        color: $text-400;
      }

      p {
        color: $text-400;
        font-family: "Merriweather Sans";
        margin: 0;
      }
    }
  }
}

.textured-background {
  color: $primary-500;
  position: relative;

  &.about-section {
    background-color: rgba(255, 255, 255, 0);
    z-index: 2;
    display: flex;
    align-items: center;
  }

  .description-container {
    color: $primary-500;
    h4 {
      font-family: Roboto Slab;
    }
    .description {
      color: $text-400;
      font-family: Merriweather Sans;
    }
  }

  .image {
    width: 90%;
  }

  .link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    font-family: Merriweather Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
    text-decoration-line: underline;
    cursor: pointer;
  }
  &:after {
    content: "";
    z-index: -1;
    background: url("../../styles/images/SCOMenuTextureGraphic.webp") no-repeat,
      radial-gradient(circle, #f2f2f0, #e0e0e0);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 20%;
    border-radius: 0.5rem;
  }
}

.additional-records-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 3.3rem 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 2;
  gap: 0.5rem;

  h4 {
    font-family: Roboto Slab;
  }
  p {
    max-width: 80vw;
    color: $text-400;
    font-family: Merriweather Sans;
  }
}

.about-section {
  &.controller {
    background-color: rgba(255, 255, 255, 0);
    .description-container {
      color: $primary-500;
    }
  }
  .link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    font-family: Merriweather Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
    text-decoration-line: underline;
    cursor: pointer;
  }
}
