@use "../../styles/" as *;

.quick-link-card {
  display: flex;
  position: relative;
  width: 22.75rem;
  height: 20.8rem;
  flex-direction: column;
  background-color: $surface-100;
  border-radius: 0.625rem;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: $elevation-1;

  &:hover {
    box-shadow: $elevation-3;
  }

  .quick-link-image {
    min-height: 10.75rem;
    max-height: 10.75rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    object-fit: cover;
    width: 100%;
    box-sizing: border-box;
  }

  .image-position-top {
    object-position: top;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    padding: 0.84rem 1.25rem 0 1.25rem;

    .title {
      color: $text-500;
      font-family: $merriweather;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      padding-bottom: 0.5rem;
    }

    .description {
      font-family: $merriweather;
      color: $text-500;
      white-space: pre-wrap;
    }
  }

  .read-more-container {
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 0.79rem;
    right: 1.25rem;
  }

  .read-more-text {
    display: flex;
    text-underline-offset: 2.5px;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0;
    text-decoration: underline;
    color: $text-700;
  }

  .read-more-icon {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 1275px) {
  .quick-link-card {
    // 8% smaller
    width: 18.5rem;
    .text-container {
      .title {
        font-size: medium;
      }
      .description {
        font-size: small;
      }
    }
    .read-more-text {
      font-size: medium;
    }
  }
}

@media screen and (max-width: 1050px) {
  .quick-link-card {
    // 10% smaller
    width: 17rem;

    .description {
      white-space: normal;
    }
  }
}
