@use '../../styles/' as *;

.download-file-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  line-height: 1.25rem;
  font-size: .875rem;
  font-family: $merriweather;
}

.download-icon {
  color: $primary-400;
  width: 20px;
  height: 24px;
  padding-right: 6px;
}

.download-text-container {
  display: flex;
  min-width: 12rem;
  max-width: 12rem;
}

.download-text {
  margin: 0;
  font-weight: '300';
  color: $text-500;
}