@use "../../styles/" as *;

.header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  color: $text-700;
  margin-bottom: 1rem;
  height: 10rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.navigation-container {
  display: flex;
  direction: row;
  justify-content: space-between;
  gap: 5vw;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 1.25rem;
  z-index: 4;
}

.logo-image {
  object-fit: contain;
  cursor: pointer;
  min-width: 13rem;
  max-width: 15.625rem;
  padding-top: 1.25rem;
  padding-left: 2.5rem;
}

.explore-button-container {
  white-space: nowrap;
  max-width: 19rem;
}

.navigation-container > span {
  padding-bottom: 1.25rem;
}

.mega-menu-link {
  display: block;
  border: 1px solid transparent;
  padding: 0.25rem 0.5rem;
  &[aria-current="true"] {
    border-radius: 0.375rem;
    border: 1px solid $additional-blue-100;
    background: #f0f3f48a;
  }
}

.navigation-link {
  font-family: $merriweather;
  font-weight: 700;
  color: $text-700;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
}

.navigation-link__current-page {
  text-decoration: underline;
}

.navigation-link:active {
  color: $text-700;
  font-weight: 700;
}

@media screen and (max-width: 1275px) {
  .navigation-container {
    gap: 2.5vw;
  }
  .navigation-link {
    font-size: medium;
  }
}

@media screen and (max-width: 1200px) {
  .logo-image {
    min-width: 10rem;
    max-width: 15rem;
    padding-top: 0;
  }
}

@media screen and (max-width: 1100px) {
  .navigation-container {
    gap: 2.25vw;
  }

  .logo-image {
    min-width: 10rem;
    max-width: 14rem;
    padding-top: 0;
  }
}

@media screen and (max-width: 1000px) {
  .logo-image {
    min-width: 10rem;
    max-width: 12.25rem;
    padding-top: 0;
  }
}

@media screen and (max-width: 720px) {
  .logo-image {
    padding-left: 0;
  }
}

@media screen and (max-width: 450px) {
  .logo-image {
    padding-left: 0;
  }

  .logo-image {
    min-width: 8rem;
    max-width: 10rem;
  }

  .header {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 360px) {
  .logo-image {
    min-width: 7rem;
    max-width: 9rem;
  }

  .header {
    padding-left: 0.75rem;
  }
}
