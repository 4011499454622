@use "../../styles/" as *;

.card-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1.5rem;
  background-color: #ffff;
  border-radius: 1rem;
  font-family: $merriweather;
  box-shadow: $elevation-1;
  transition: box-shadow 0.3s ease-in-out;
  min-width: 20.75rem;
  max-width: 20.75rem;
  min-height: 27.75rem;
  max-height: 27.75rem;
  cursor: pointer;
}

#card:hover {
  box-shadow: $elevation-3;
}

.img-container{
position: relative;
margin-bottom: 1rem;

&.coming-soon-img::after {
  content: ''; 
  position: absolute; 
  top: 1px;
  left: 1px;
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.2); 
  border-radius: 1rem;
}

.tag-coming {
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 2;
}

}
.card-image {
  position: relative; 
  object-fit: cover;
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  border-radius: 1rem;
  justify-self: center;
  border: 1px solid #f2f2f0;
}

.tag-name {
  font-weight: 300;
  font-family: $merriweather;
  color: $surface-100;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 90px;
}

.tag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}

.tag-coming {
  font-weight: 500;
  color: $text-500;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 90px;
  border: solid 1px;
  background-color: #f2f2f0;
  border-color: $text-500;
}

.tag-Education {
  background-color: $purple-300;
}

.tag-Counties {
  background-color: $additional-green-400;
}

.tag-City {
  background-color: $blue-300;
}

.tag-LocalDistrict {
  background-color: $yellow-400;
}

.tag-State {
  background-color: $orange-300;
}

.tag-WorkforceSalary {
  background-color: $green-300;
}

.vertical-card-title {
  color: $text-500;
  font-weight: 700;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.5rem;
  white-space: pre-wrap;
  margin: 0;
}

.vertical-card-description {
  margin: 0;
  color: $text-500;
  font-weight: normal;
}

.button-readmore-container {
  display: flex;
  position: absolute;
  align-items: center;
  right: 1.5rem;
  bottom: 1.5rem;
}

.button-readmore-text {
  display: flex;
  text-underline-offset: 2.5px;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 0;
  text-decoration: underline;
  color: $text-700;
}

.readmore-icon {
  margin-left: 0.5rem;
}

@media screen and (max-width: 1220px) {
  .card-container {
    min-width: 18.75rem;
    max-width: 18.75rem;
    min-height: 28rem;
    max-height: 28rem;
    padding: 1rem;
  }

  .vertical-card-title {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-weight: 700;
    font-size: 1.25rem;
  }

  .vertical-card-description {
    font-size: 1rem;
  }

  .button-readmore-container {
    right: 1rem;
    bottom: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .card-container {
    min-width: 15.75rem;
    max-width: 15.75rem;
    min-height: 25rem;
    max-height: 25rem;
    padding: 1rem;
  }

  .vertical-card-title {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-weight: 700;
    font-size: 1.25rem;
  }

  .vertical-card-description {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .button-readmore-container {
    right: 1rem;
    bottom: 1rem;
  }
}
