@use "../../styles/" as *;

.county-page-container {
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.county-hero-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 80vh;
  max-height: 519px;
  padding-left: 7.25rem;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.county-hero-background-mobile {
  display: none;
}

.county-hero-background {
  display: flex;
  position: absolute;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 49.4375rem;
  z-index: -1;
  bottom: 0;
  left: 0;
}

.icon-bubble {
  color: $text-100;
}

.align-center {
  align-items: center;
}

.localEntityTable {
  margin-top: 3rem;
}

.explore-section {
  display: flex;
  flex-direction: column;
  width: 55vw;
  max-width: 900px;
  z-index: 2;
  height: 100%;
  align-items: space-between;
  justify-content: space-between;
}

.explore-info-text {
  display: flex;
  flex-direction: column;
}

.explore-idaho-col {
  display: flex;
  position: relative;
  padding-top: 4rem;
}

.county-hero-info {
  display: flex;
  flex-direction: row;
  white-space: pre-line;
  align-items: start;
}

.county-page-seal-image {
  min-width: 80px;
  max-width: 80px;
  padding-top: 0rem;
  margin: 0;
  margin-right: 2rem;
  border-radius: 0.5rem;
}

.county-header {
  font-family: $roboto;
  text-shadow: 0px 1px 4px rgba(24, 27, 32, 0.2);
  margin-bottom: 0.5rem;
  color: $text-100;
}

.county-text {
  font-family: $merriweather;

  text-shadow: 0px 2px 8px rgba(24, 27, 32, 0.25);
  margin-top: 0;
  font-weight: 400;
  font-size: 20px;
  color: $text-100;
  padding: 0.2rem;
}

.info-bubble-row-county {
  display: flex;
  flex-direction: row;
  min-width: 75%;
  justify-content: space-between;
}

.about-individual-county-section {
  display: flex;
  flex-direction: row;
  background-color: $primary-neutral-500;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  padding-left: 89px;
  align-items: center;
  z-index: 2;
  gap: 15vw;
  overflow-x: hidden;
}

.about-description-header {
  padding-bottom: 1.25rem;
  font-family: $roboto;
  white-space: nowrap;
}

.about-description-text {
  font-family: $merriweather;
  height: 22rem;
  width: 25.5625rem;
  overflow-y: auto;
  padding-right: 1rem;
  min-width: 12rem;
  white-space: pre-line;
  -webkit-overflow-scrolling: touch;
}

.about-description-text::-webkit-scrollbar {
  width: 0.75rem;
  background-color: $scrollbar-track;
  border-radius: 0.5rem;
}

.about-description-text::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb;
  border-radius: 1rem;
}

.about-description-county-container {
  max-width: 25.625rem;
  color: $text-100;
}

.report-data-link {
  font-family: $merriweather;
  margin-top: 2rem;
  text-decoration: underline;
  cursor: pointer;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 2rem;
}

.filter-table {
  height: 30rem;
  max-width: 70rem;
  overflow-y: auto;
  padding-right: 0.8rem;
  position: relative;
  z-index: 0;
  margin-top: 2rem;
  -webkit-overflow-scrolling: touch;
  &.auto-height-table {
    height: auto;
  }
}

.filter-table::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.5rem;
  background-color: $scrollbar-track;
  border-radius: 0.5rem;
}

.filter-table::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb;
  border-radius: 1rem;
}

.county-search-entity-section {
  display: flex;
  flex-direction: column;
  background-color: $surface-100;
  z-index: 0;
}

.county-entity-search-headers {
  padding-bottom: 1rem;
}

.county-entity-search-header {
  margin-top: 0;
  color: $text-700;
  font-family: $roboto;
  font-weight: 700;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}

.county-entity-search-subheader {
  font-family: $merriweather;
  color: $text-400;
  width: 45%;
  white-space: pre-line;
}

.county-entity-search-menu-header {
  font-family: $merriweather;
  text-transform: uppercase;
  font-weight: 400;
  color: $text-500;
  padding-bottom: 0.75rem;
}

.county-page-background-color-blue {
  background-color: $surface-200;
}

.county-page-reports-light {
  background-color: $surface-100;
  padding-bottom: 4.5rem;
}

.county-page-reports-blue {
  background-color: $surface-200;
  padding-bottom: 4.5rem;
}

.county-page-report-header {
  font-weight: 700;
  font-family: $roboto;
  padding-top: 4.5rem;
  color: $text-700;
  text-transform: uppercase;
}

.county-page-last-report {
  padding-bottom: 4.5rem;
}

.county-page-report-subheader {
  font-family: $merriweather;
  color: $text-400;
  white-space: pre-wrap;
  padding-top: 0.5rem;
}

.county-report-cards {
  display: flex;
  z-index: 1;
  justify-content: center;
  background-color: $surface-100;
}

.report-cards-container {
  display: flex;
  flex-direction: column;
  padding-top: 4.5rem;
  z-index: 1;
}

.cards-container {
  display: flex;
  column-gap: 1rem;
  padding-top: 0.875rem;
  -webkit-overflow-scrolling: touch;
}

.cards-container::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.3rem;
  background-color: $scrollbar-track;
}

.cards-container::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb;
  border-radius: 1rem;
}

.cards-container::-webkit-scrollbar-track {
  border-radius: 50%;
}

.report-cards-header {
  text-transform: uppercase;
  font-weight: 700;
  color: $text-700;
  font-family: $roboto;
}

.report-cards-subheader {
  color: $text-400;
  padding-top: 0.5rem;
  font-family: $merriweather;
  font-weight: 400;
  text-transform: uppercase;
}

.county-anchor-tag {
  text-decoration: none;
  color: inherit;
}

.local-district-here-link {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
  font-weight: bold;
  color: $text-700;
}

@media screen and (min-width: $desktop-min) {
  .county-seal {
    padding-top: 3.75rem;
  }
}

@media screen and (max-width: 1200px) {
  .report-cards-header {
    padding-left: 0;
  }

  .county-search-entity-section {
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 4.5rem;
  }

  .report-cards-subheader {
    padding-left: 0;
  }

  .cards-container {
    width: 90vw;
    overflow-x: auto;
    margin: 1rem 0;
    padding-bottom: 1.5rem;
  }

  .about-individual-county-section {
    padding-left: 2rem;
    padding-right: 2rem;
    justify-content: center;
    gap: 13vw;
  }

  .about-description-header {
    font-size: 1.6rem;
  }

  .about-description-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .about-description-county-container {
    max-width: 75%;
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 3rem;
  }

  .county-text {
    font-family: $merriweather;
    margin-top: 0;
    font-size: 16px;
    line-height: 16px;
    color: $text-100;
  }

  .county-seal {
    width: 5rem;
    height: 5rem;
    padding-top: 0.9rem;
  }

  .explore-info-col {
    width: 70vw;
  }
}

@media screen and (max-width: 1100px) {
  .about-individual-county-section {
    gap: 3rem;
  }
}

@media screen and (max-width: 1000px) {
  .county-hero-info {
    width: 70vw;
  }

  .info-bubble-row-county {
    width: 60vw;
  }

  .about-individual-county-section {
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 8vw;
  }

  .about-description-county-container {
    padding: 0;
    width: 100%;
  }
}

@media screen and (max-width: 875px) {
  .about-individual-county-section {
    flex-direction: column;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .county-page-report-subheader {
    white-space: normal;
  }

  .about-description-text {
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .county-hero-container {
    padding-left: 0;
    height: 100%;

    .county-hero-info {
      padding: 0;
      align-items: center;
    }

    .explore-info-text {
      align-items: center;
    }

    .explore-section {
      align-items: center;
      width: 100%;
      margin: 0;
    }
    .glass {
      border-radius: 0;
    }
  }

  .info-bubble-row-county {
    padding-top: 4.5rem;
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .county-hero-info {
    width: 80vw;
  }

  .county-entity-search-subheader {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .about-description-header {
    white-space: normal;
  }

  .report-cards-container {
    padding-left: 1rem;
  }

  .county-page-report-header {
    padding-top: 3rem;
  }

  .about-individual-county-section {
    padding-left: 0.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .idaho-map-container {
    display: none;
  }

  .county-hero-container {
    padding: 0;
    max-height: none;
  }

  .county-hero-info {
    flex-direction: column;
    width: auto;
    border-radius: 0;
    border: 0;
    padding-bottom: 0;
    padding-top: 1rem;
    height: 18rem;
    text-align: center;
    background: none;
    box-shadow: none;
    backdrop-filter: none;
  }

  .county-page-seal-image {
    margin: 0;
  }

  .explore-section {
    width: 100%;
    align-items: center;
  }

  .info-bubble-row-county {
    flex-direction: column;
    align-items: center;
    background-color: $primary-400;
    padding: 4.5rem 0;
    gap: 3rem;
    width: 100%;
    z-index: 1;
  }

  .county-hero-background-mobile {
    z-index: -1;
    display: flex;
    position: absolute;
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 19rem;
    top: 0;
    right: 0;
  }

  .county-hero-background {
    display: none;
  }

  .county-header {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .idaho-map-container {
    display: none;
  }
}
