$primary-500: #404e65;
$primary-400: #3e5e94;
$primary-300: #9cb9ec;
$primary-200: #c6d8f6;
$primary-100: #f2f7ff;

$secondary-500: #00403b;
$secondary-400: #017369;
$secondary-300: #47bfb5;
$secondary-200: #79dfd6;
$secondary-100: #b5fff9;

$primary-neutral-500: #212121;
$primary-neutral-400: #5d6063;
$primary-neutral-300: #a7a9ab;
$primary-neutral-200: #d7d8da;
$primary-neutral-100: #fcfdff;

$secondary-neutral-500: #402e00;
$secondary-neutral-400: #877445;
$secondary-neutral-300: #bf9d47;
$secondary-neutral-200: #ccaf64;
$secondary-neutral-100: #dfcfa7;

$surface-glass: #3e5d9498;
$surface-glass-dark: #3f5273;
$surface-500: #dedddc;
$surface-400: #e2e1e0;
$surface-300: #edeceb;
$surface-200: #f0f3f4;
$surface-100: #fcfaf5;

$text-700: #404e65;
$text-600: #806e41;
$text-500: #1e1e1d;
$text-400: #565654;
$text-300: #82817f;
$text-200: #adacaa;
$text-100: #ffffff;

$graphic-700: linear-gradient(182.95deg, #3e5e94 -14.48%, #404e65 97.55%);
$graphic-600: linear-gradient(90.01deg, #808183 61.11%, #cccdcf 100%);
$graphic-500: #1e1e1d;
$graphic-400: #565654;
$graphic-300: #565654;
$graphic-200: #adacaa;
$graphic-100: #ffffff;

$red-500: #db0404;
$red-400: #a1402f;
$red-300: #d6553e;
$red-200: #d9978b;
$red-100: #a1908d;

$green-400: #00403b;
$green-300: #01746a;
$green-200: #8bd9d2;
$green-100: #8da19f;

$orange-300: #ce6228;

$yellow-400: #a1792f;
$yellow-300: #ffbe41;
$yellow-200: #d9bd8b;
$yellow-100: #a19a8d;

$purple-400: #5d0066;
$purple-300: #8d4194;
$purple-200: #d28bd9;
$purple-100: #9f8da1;

$blue-400: #001740;
$blue-300: #0c306e;
$blue-200: #8ba7d9;
$blue-100: #8d94a1;

$additional-blue-100: #536da6;
$additional-green-400: #006611;

$scrollbar-thumb: #a7a9ab;
$scrollbar-track: #d7d8da;
