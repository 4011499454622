@use "./styles/" as *;

.app {
  display: flex;
  flex-direction: column;
  background-color: $surface-100;
  color: $text-500;
  height: 100%;
  min-height: 100vh;
}

.footer {
  position: absolute;
  bottom: 0;
}

.app-padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

h1 {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 2.25rem;
  white-space: pre-line;
  margin: 0;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 0;
}

h3 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
}

h5 {
  font-size: 1.125rem;
  margin: 0;
}

h6 {
  font-size: 1.125rem;
  margin: 0;
}

.body-extra-large {
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 0;
}

.body-large {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0;
}

.body-medium {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.body-small {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.body-extra-small {
  font-size: 0.625rem;
  line-height: 1rem;
  margin: 0;
}

.button-text-large {
  font-size: 1.25rem;
  font-weight: medium;
  line-height: 2.25rem;
  margin: 0;
}

.button-text-medium {
  font-size: 1.125rem;
  font-weight: medium;
  line-height: 1.75rem;
  margin: 0;
}

.button-text-small {
  font-size: 0.875rem;
  font-weight: medium;
  line-height: 1rem;
  margin: 0;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem;
  padding: 0.75rem 2rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: fit-content;
}

.gap-1 {
  gap: 1rem;
}

.invert {
  filter: brightness(0) invert(1);
}

.text-white {
  color: $text-100;
}

.glass {
  background-color: $surface-glass;
  backdrop-filter: blur(5px);
  border-radius: 1rem;
}

.gutter {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (min-width: $tablet-min) {
  .app-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gutter {
    padding: 1.5rem;
  }
}

@media screen and (min-width: $desktop-min) {
  .app-padding {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  h1 {
    font-size: 2.75rem;
    font-weight: bold;
    line-height: 3.75rem;
    margin: 0;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 3rem;
    margin: 0;
  }

  h3 {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: bold;
    margin: 0;
  }

  h4 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;
    margin: 0;
  }

  h5 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: bold;
    margin: 0;
  }

  h6 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  .gutter {
    padding: 2rem;
  }
}

@media screen and (min-width: 1920px) {
  .app {
    max-width: 1920px;
    margin: 0 auto;
  }

  .app-padding {
    padding-left: 6.5rem;
  }
}
