@use "../../styles/" as *;

.footer-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 40rem;
  justify-content: flex-end;
  background-color: $surface-100;
  font-family: $merriweather;

  a {
    color: $text-100;
    text-decoration: none;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background-color: #393939;
  }

  .mountains-graphic {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .scroll-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 3rem;
    padding: 0.005rem;
    background-color: $secondary-400;
    border: none;
    border-radius: 50%;
    box-shadow: $elevation-3;
    z-index: 15;

    &:hover {
      box-shadow: $elevation-4;
      transition: all 0.5s ease-in-out;
      transform: scale(1.05);
    }

    &.scroll-button-fixed {
      position: fixed;
      right: 1rem;
      bottom: 1rem;
      top: auto;
    }
  }

  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    z-index: 2;
    color: $text-100;
    padding-bottom: 2.5rem;
    gap: 1rem;
  }

  .footer-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 50%;
  }

  .brandon-pill {
    display: flex;
    position: relative;
    width: 32rem;
    min-width: 32rem;

    &:hover {
      cursor: pointer;
    }

    .brandon-pill-graphic {
      width: 100%;
    }

    .brandon-pill-text {
      position: absolute;
      right: 1rem;
      top: 4rem;
      bottom: 1rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      p {
        margin: 0.5rem 0;
      }

      img {
        width: 12rem;
      }
    }
  }

  .address-container {
    h2 {
      font-size: 1rem;
    }
    p {
      margin: 0.5rem 0;
    }
  }

  .socials {
    display: flex;
    gap: 1rem;
    padding: 0 1rem 0 0;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      border-radius: 50%;
      border: none;
      background-color: $primary-100;
      background-color: $primary-neutral-400;
      cursor: pointer;
      &:hover {
        box-shadow: $elevation-4;
        transition: all 0.5s ease-in-out;
        transform: scale(1.05);
      }
    }
  }
}

.footer-bottom-bar {
  position: relative;
  color: $text-100;
  background-color: $secondary-neutral-400;
  font-family: $merriweather;
  font-size: 1rem;

  .floating-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    color: $text-100;
    font-family: $merriweather;
    height: 3.2rem;

    border-radius: 0.4rem 0.4rem 0 0;
    padding: 0.75rem 1.25rem;

    border: none;
    cursor: pointer;
    width: fit-content;

    &:hover {
      box-shadow: $elevation-4;
      transition: all 0.5s ease-in-out;
      transform: scale(1.05);
    }

    &.public-button {
      background-color: $green-300;
      right: 12.5rem;
    }

    &.contact-button {
      background-color: $primary-400;
      right: 2rem;
    }
  }

  .links {
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    width: calc(50vw + 8rem);
    max-width: calc(100vw - 27rem);
    font-weight: 400;
    a {
      color: $text-100;
      font-family: $merriweather;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 1100px) {
  .footer-bottom-bar {
    .links {
      justify-content: start;
    }
  }
}

@media screen and (max-width: 960px) {
  .footer-container {
    .footer-right {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 850px) {
  .footer-bottom-bar {
    min-height: 6rem;
    .links {
      align-items: flex-start;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.5rem;
      p {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .footer-container {
    .scroll-button {
      margin: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      svg {
        margin: -1rem;
        transform: scale(0.6);
      }
      &.scroll-button-fixed {
        position: fixed;
        right: 1rem;
        bottom: 1rem;
        top: auto;
      }
    }
    .footer-right {
      align-items: center;
      width: auto;
    }
    .mountains-graphic {
      bottom: 4rem;
    }
    .footer-content {
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;
    }
  }
}

@media screen and (max-width: 630px) {
  .footer-bottom-bar {
    min-height: 8rem;
    .links {
      width: 100%;
      max-width: 100%;
      align-items: center;
    }
    .floating-button {
      height: 2rem;
      padding: 0 0.75rem;
      &.contact-button {
        right: auto;
        left: calc(50vw + 2rem);
      }
      &.public-button {
        right: calc(50vw - 1rem);
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .footer-container {
    height: 34rem;
    .footer-content {
      gap: 0;
    }
    .brandon-pill {
      transform: scale(0.8);
      margin-bottom: -1rem;
    }
  }
}

@media screen and (max-width: 440px) {
  .footer-container {
    height: 26rem;
    .mountains-graphic {
      bottom: 3rem;
    }
    .brandon-pill {
      transform: scale(0.6);
      margin-bottom: -3rem;
    }
  }
}

@media screen and (max-width: 340px) {
  .footer-container {
    height: 22rem;
    .mountains-graphic {
      bottom: 2rem;
    }
    .brandon-pill {
      transform: scale(0.4);
      margin-bottom: -5.5rem;
    }
    .footer-right .address-container {
      transform: scale(0.75);
    }
  }
  .footer-bottom-bar {
    min-height: 12rem;
    .links {
      align-items: flex-start;
    }
    .floating-button {
      position: initial;
      width: calc(100% - 1.5rem);
      border-radius: 0.4rem;
      margin-top: 0.2rem;
    }
  }
}
