@use "../styles/" as *;

.landing-page-container {
  display: flex;
  flex-direction: column;
  background-color: $surface-100;
}

.landing-page-picture-background {
  background-image: url("../styles/images/backgroundCutout2.webp");
  background-repeat: no-repeat;
  background-position: top;
  background-position-x: right;
  background-size: 67%;
  background-color: $surface-100;
  &.public-records-page {
    background-image: url("../styles/images/backgroundCutout3.webp");
  }
}

.landing-page-hero-container {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 7.25rem;
  min-height: 663px;
}

.landing-page-hero-header {
  font-family: $roboto;
  color: $text-700;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}

.landing-page-hero-subheader {
  font-family: $merriweather;
  color: $text-400;
  width: 85%;
  font-weight: normal;
  font-size: 1.2rem;
  margin: 0;
}

.landing-page-hero-info {
  display: flex;
  flex-direction: row;
  white-space: pre-line;
  min-height: 10rem;
  max-height: 13rem;
}

.landing-page-hero-data {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-top: 8rem;
  height: fit-content;
}

.landing-page-explore-info-text {
  width: 35rem;
}

.landing-page-idaho-state-seal {
  min-width: 4.5rem;
  max-width: 4.5rem;
  min-height: 4.5rem;
  max-height: 4.5rem;
  padding-top: 0.5rem;
  padding-right: 1.75rem;
}

.landing-page-info-bubbles {
  display: flex;
  flex-direction: row;
  min-width: 60%;
  justify-content: space-between;
}

.hero-separator {
  display: flex;
  background: linear-gradient(90.01deg, #808183 61.11%, #cccdcf 100%);
  height: 2rem;
}

.landing-page-background-blue {
  background-color: $surface-200;
}

.landing-page-reports-container {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  position: relative;
}

.landing-page-high-level-header {
  color: $text-700;
  font-family: $roboto;
}

.landing-page-high-level-subheader {
  padding-top: 0.5rem;
  color: $text-400;
  font-family: $merriweather;
  width: 45%;
}

@media screen and (max-width: 1650px) {
  .landing-page-hero-data.state .landing-page-hero-info span {
    height: fit-content;
  }
}

@media screen and (min-width: 1550px) {
  .landing-page-picture-background {
    background-size: 65rem;
  }
}

// @media screen and (max-width: 1350px) {
//   .landing-page-picture-background {
//     background-size: 58.5%;
//   }
// }

@media screen and (max-width: 1600px) {
  .landing-page-idaho-seal {
    min-width: 5rem;
    max-width: 5rem;
    min-height: 5rem;
    max-height: 5rem;
    padding-top: 0.55rem;
    padding-right: 2rem;
  }

  .landing-page-hero-container {
    padding-left: 4rem;
  }
}

@media screen and (max-width: 1100px) {
  .landing-page-high-level-header {
    font-size: 1.75rem;
    line-height: 36px;
  }

  .landing-page-reports-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .landing-page-hero-info {
    width: 55vw;
  }

  .landing-page-hero-subheader {
    width: 100%;
  }

  // .landing-page-picture-background {
  //   background-size: 50%;
  // }

  .landing-page-info-bubbles {
    width: 65vw;
  }
}

@media screen and (max-width: 960px) {
  .landing-page-picture-background {
    background-size: 63%;
  }
}

@media screen and (max-width: 900px) {
  .landing-page-hero-container {
    padding-left: 3rem;
  }

  .landing-page-info-bubbles {
    width: 80vw;
  }
}

@media screen and (max-width: 800px) {
  .landing-page-hero-info {
    padding-top: 2rem;
    width: 65vw;
  }
}

@media screen and (max-width: 900px) {
  .landing-page-hero-container {
    padding-left: 2rem;
  }

  .landing-page-picture-background {
    background-size: 62.5%;
  }

  .landing-page-high-level-subheader {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .landing-page-info-bubbles {
    width: 80vw;
  }
}

@media screen and (max-width: 520px) {
  .landing-page-hero-container {
    padding-left: 1rem;
    padding-top: 0;
  }

  .landing-page-picture-background {
    background-size: 70.5%;
  }

  .landing-page-hero-info {
    width: 90vw;
  }

  .landing-page-info-bubbles {
    height: auto;
  }

  .landing-page-hero-header {
    white-space: normal;
  }
}

@media screen and (max-width: 500px) {
  .landing-page-hero-container {
    padding: 0;
    justify-content: center;
    min-width: 100%;
    height: fit-content;
    min-height: fit-content;
    padding-bottom: 4.37rem;
  }

  .landing-page-hero-data {
    margin-top: 0rem;
    padding: 0;
  }

  .landing-page-hero-info {
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 0;
    max-height: none;
    padding: 0rem;
  }

  .landing-page-hero-subheader {
    width: 100%;
  }

  .landing-page-explore-info-text {
    text-align: center;
    width: 80vw;
  }

  .landing-page-idaho-state-seal {
    padding: 0;
  }

  .landing-page-info-bubbles {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    gap: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .landing-page-picture-background {
    background-size: 75%;
  }
}

@media screen and (max-width: 400px) {
  .landing-page-picture-background {
    background-size: 72%;
  }
}
