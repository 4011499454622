@use '../../styles/' as *;

.navbar-container {
  background-color: $primary-400;
  display: flex;
  padding: 0 4rem;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  font-family: $merriweather;
}

.header-text {
  font-weight: 500;
  margin: 0;
  color: $primary-100;
}

.header-phone {
  font-weight: 500;
  margin: 0;
  padding-left: 4px;
  color: $primary-100;
  white-space: nowrap;
}

.header-right {
  display: flex;
  align-items: center;
}

.search-box {
  height: 36px;
  width: 300px;
  border-radius: 10px;
  border: 0;
  margin-left: 4rem;
  margin-right: 2rem;
  padding-left: 1rem;
}

.phone-icon {
  color: $primary-100;
}

.phone-container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 845px) {
  .display-none {
    display: none;
  }

  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .phone-container {
    margin-left: 2rem;
  }

  .header-phone {
    font-size: 22px;
  }

  .header-right {
    justify-content: flex-end;
  }

  .navbar-container {
    padding: 0 2rem;
  }
}