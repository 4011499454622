@use "../../../../styles/" as *;

.home-page-hero-section {
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 10rem;
  background-image: url("../../../../styles/images/HeroBGImage.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  height: 650px;
  margin-top: 5rem;

  .hero-info {
    width: 50.75rem;
    font-family: $roboto;
    color: $primary-500;

    & > h1 {
      font-size: 5.75rem;
      font-weight: 900;

      & > span {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    & > p {
      line-height: normal;
      margin: 2rem 0;
      font-weight: 700;
      font-size: 2rem;
    }
  }
}

@media screen and (min-width: 1500px) {
  .home-page-hero-section {
    background-size: cover;
    height: 663px;
    background-position: top;
  }
}

@media screen and (max-width: 1500px) {
  .home-page-hero-section {
    padding-left: 6rem;
  }
}

@media screen and (max-width: 1400px) {
  .home-page-hero-section .hero-info {
    width: 90%;
    max-width: 39.7rem;
    & > h1 {
      font-size: 4.5rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .home-page-hero-section {
    height: auto;
    padding: 0 0 12rem 0;
    justify-content: center;
    .hero-info > p {
      margin: 1rem 0;
    }
  }
}

@media screen and (max-width: 960px) {
  .home-page-hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position-x: 10%;
    margin-top: 1.5rem;
    .hero-info {
      text-align: center;
      & > h1 {
        font-size: 3rem;
        line-height: 3rem;

      }
      & > p {
        font-size: 1.5rem;
      }
    }
  }
}
