@use "../../styles/" as *;

.uam-section {
  background-image: url("../../styles/images/UAMBackground.png");
  background-size: 103% 105%;
  padding: 1.5rem;
  margin: 4.25rem 2rem;
  border-radius: 0.5rem;
}

.uam-section p {
  max-width: 48rem;
  font-weight: 400;
  font-family: Merriweather Sans;
  font-size: 1rem;
  line-height: 1.875rem;
  color: $text-400;
}

.uam-section h2 {
  max-width: 55rem;
  font-weight: 700;
  font-size: 1.875rem;
  font-family: Merriweather Sans;
  color: $primary-500;
}

@media screen and (min-width: 1445px) {
  .uam-section {
    padding: 2.5rem;
    margin: 4.25rem 5rem;
  }

  .uam-section p {
    max-width: 70rem;
    font-size: 1.438rem;
  }

  .uam-section h2 {
    font-size: 2.375rem;
  }
}

@media screen and (max-width: 1024px) {
  .uam-section {
    background: url("../../styles/images/UAMLaptopBackground.png");
    padding: 1.5rem;
    background-repeat: no-repeat;
    width: 90%;
  }

  .uam-section p {
    max-width: 37rem;
    font-size: 0.938rem;
  }

  .uam-section h2 {
    font-size: 1.938rem;
  }
}

@media screen and (max-width: 768px) {
  .uam-section {
    background: url("../../styles/images/UAMTabBackground.png");
    margin: 2rem 2rem;
    width: auto;
  }

  .uam-section p {
    font-size: 0.8125rem;
    max-width: 25rem;
  }

  .uam-section h2 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 480px) {
  .uam-section {
    background-image: url("../../styles/images/SCOCarouselMobileBanner4.png");
    margin: 2rem 1rem;
  }

  .uam-section p {
    line-height: 1.25rem;
    max-width: 100%;
  }

  .uam-section h2 {
    font-size: 1.313rem;
  }
}
