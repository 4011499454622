@use "../../styles/" as *;

.about-section {
  display: flex;
  gap: 5.8125rem;
  background-color: $blue-400;
  padding: 4.5rem;
  justify-content: space-between;

  @media screen and (min-width: 1400px) {
    justify-content: flex-start;
    padding: 4.5rem 10rem;
    gap: 7rem;
  }

  @media screen and (max-width: 1200px) {
    gap: 2rem;
    padding: 3rem;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
  }

  @media screen and (max-width: 700px) {
    padding: 4rem 0;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    width: 33rem;
    max-height: 32rem;
    color: $text-100;

    @media screen and (max-width: 500px) {
      width: 80%;
      gap: 0;
    }

    .title {
      font-family: $roboto;
      padding-bottom: 1.25rem;
      font-size: 2rem;
    }

    .description,
    .data-info {
      font-family: $merriweather;
    }

    .description {
      max-height: 22.5rem;
      position: relative;
      overflow-y: auto;
      white-space: pre-line;
      padding: 0;
      padding-right: 1rem;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.75rem;
        background-color: $scrollbar-track;
        border-radius: 1rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
        border-radius: 1rem;
      }
    }

    .data-info {
      padding-top: 1.5rem;
      font-style: italic;
    }
  }

  .image {
    max-width: 36.875rem;
    max-height: 25.75rem;
    object-fit: cover;
    border-radius: 0.5rem;
    image-resolution: 2;

    @media screen and (max-width: 1100px) {
      max-height: 18rem;
    }

    @media screen and (max-width: 900px) {
      max-width: 80vw;
      margin-top: 2.5rem;
    }
  }

  .description-container a {
    color: $text-100;
  }

  .description-container a:hover {
    color: lightgray;
  }
}
