.search-bar-error {
  border: 0;
  font-family: Merriweather-Sans;
  font-size: 1.125rem;
  border-radius: .375em;
  background-color: #dedddc;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
  width: 25.5em;
  height: 8em;
  line-height: 1.5rem;
  padding: 0 1rem;
  color: #404e65;
  background: url(../../styles/images/city-bg-texture.webp);
  display: flex;
  p {
    margin:  0;
    align-self: center;
  }
}

.transparent-search-bar {
  position: absolute;
  top: 0;
  right: 2rem;
  width: 408px;
  z-index: 12;
  overflow: hidden;
  &.hide-iframe {
    height: 148px;
  }

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 920px) {
    display: none;
    &.mobile {
      display: block;
      bottom: 0;
      right: none;
      left: 50%;
      transform: scale(0.8) translateX(-62.5%);
    }
  }

  &.hide-iframe {
    height: 148px;
  }
}

.mobile-container {
  display: flex;
  position: relative;
  @media screen and (max-width: 920px) {
    height: 10rem;
  }
}