@use '../../../../styles/' as *;

.cards-section-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.section-title {
  color: $text-600;
  font-family: $roboto;
  padding-bottom: .5rem;
}

.section-subtitle {
  margin-bottom: 1.5rem;
  color: $text-400;
  font-family: $merriweather;
  padding-bottom: 0.875rem;
}

.section-gutter {
  gap: 2rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
}

.wrapper {
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 2.5rem;
}

@media screen and (max-width: 1000px) {
  .section-subtitle {
    font-weight: 400;
    font-size: 18px !important;
    line-height: 24px;
  }

  .section-title {
    font-weight: 700;
    font-size: 24px !important;
    line-height: 32px;
  }
}

@media screen and (max-width: 1220px) {
  .section-header {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.1875rem;
  }

  .wrapper {
    overflow-x: visible;
    overflow-y: hidden;
    width: 95vw;
    margin-left: 0.5rem;
    -webkit-overflow-scrolling: touch;
  }

  .wrapper::-webkit-scrollbar {
    width: .75rem;
    height: .3rem;
    background-color: $scrollbar-track;
  }

  .wrapper::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb;
    border-radius: 1rem;
  }

  .wrapper::-webkit-scrollbar-track {
    border-radius: 50%;
  }

  .section-header {
    padding-bottom: 0.875rem;
  }
}

@media screen and (max-width: 400px) {
  .wrapper {
    gap: 1rem;
  }
}