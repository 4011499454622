@use "../../styles/" as *;
.sticky-nav {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
  background-color: $surface-400;
  position: sticky;
  z-index: 13;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .section,
  a {
    color: $text-700;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    gap: 0.62rem;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $text-600;
    }
    p {
      margin-bottom: 0.5rem;
      margin-top: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: center;
      line-height: 0.7;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.25rem;
      height: 2.25rem;
      margin-bottom: 1rem;
    }
  }

  .icon-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
