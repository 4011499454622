@use "../../../styles/" as *;
.state-agency-search-container {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 3rem;

  .title {
    display: flex;
    font-family: $roboto;
  }

  .sub-title {
    max-width: 32rem;
    padding-bottom: 2rem;
    font-family: Merriweather Sans;
  }

  .filter-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .or-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $primary-500;
    font-family: Merriweather Sans;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}

.agency-card {
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(24, 27, 32, 0.2);
  display: flex;
  padding: 1.5rem 1.25rem;
  align-items: center;
  align-self: stretch;
  margin: 0.05rem;
  justify-content: space-between;

  .title-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    max-width: 45vw;
    .name {
      font-family: Merriweather Sans;
      margin: 0;
    }
    .type {
      margin: 0;
      color: $primary-neutral-400;
      font-family: Merriweather Sans;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem; /* 155.556% */
    }
  }

  img {
    max-width: 2rem;
    border-radius: 0.25rem;
  }

  .link {
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.5rem;
    gap: 0.75rem;
    margin: 0;

    p {
      color: $text-700;
      font-family: Merriweather Sans;
      font-size: 0.875rem;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      text-decoration-line: underline;
    }
  }
}
