@use "../../styles/" as *;
.quick-links-container {
  display: flex;
  flex-direction: column;
  background-color: $surface-100;
  color: $text-700;
  padding: 3.12rem 4.5rem 0 4.5rem;
  padding-bottom: 3rem;
  gap: 1.5rem;

  @media screen and (min-width: 1400px) {
    padding: 3.12rem 10rem 0 10rem;
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 1200px) {
    padding: 3.12rem 3rem 0 3rem;
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }

  .links-title {
    display: flex;
    gap: 0.63rem;

    .icon {
      padding: 0.8rem 0;
      @media screen and (max-width: 900px) {
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    p {
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 1rem;
    }
    .navigate-link {
      text-decoration: none;
    }
    .link-container {
      display: flex;
      flex-direction: column;
      width: 11.1875rem;
      gap: 1.75rem;
      cursor: pointer;

      @media screen and (max-width: 900px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0.375rem;
        background: rgba(62, 94, 148, 0.85);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        font-family: Merriweather Sans;
        width: 17rem;
        height: 3rem;
        &:hover {
          background-color: rgba(62, 94, 148, 0.5);
        }
      }
      .title-container {
        @media screen and (max-width: 900px) {
          width: 60%;
        }
        h3 {
          color: $text-700;
          font-family: Roboto Slab;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 1.5rem;
          margin: 0;
          width: 13rem;
          @media screen and (max-width: 900px) {
            font-family: Merriweather Sans;
            font-size: 1rem;
            color: $text-100;
          }
        }
      }

      .link-image {
        position: relative;
        display: inline-block;
        width: 10.1875rem;
        height: 9.1875rem;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 0.5rem;
        }
        @media screen and (max-width: 900px) {
          width: 4rem;
          height: 3rem;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.5rem;
          border: 1px solid #00000000;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 0.5rem;
          box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
          @media screen and (max-width: 900px) {
            border-radius: 0;
            border-bottom-left-radius: 0.375rem;
            border-top-left-radius: 0.375rem;
            box-shadow: none;
            border: none;
          }
        }
      }
    }
    .link-container:hover {
      .link-image {
        @media screen and (min-width: 900px) {
          &::after {
            background: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}
