@use "../../../../styles/" as *;

.carousel-container {
  display: flex;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  overflow: scroll hidden;
}

.carousel__slide {
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-one-background {
  background-image: url("../../../../styles/images/SCOCarouselBanner1.webp");
}

.carousel-two-background {
  background-image: url("../../../../styles/images/SCOCarouselBanner2.webp");
  background-position-x: right;
}

.carousel-three-background {
  background-image: url("../../../../styles/images/SCOCarouselBanner3.webp");
}

.carousel-four-background {
  background-image: url("../../../../styles/images/SCOCarouselBanner4.webp");
}

.chevron-left,
.chevron-right {
  position: absolute;
  top: 45%;
  color: $blue-400;
  min-width: 120px;
  z-index: 444;
  cursor: pointer;
}

.chevron-left {
  padding-left: 50px;
}

.carousel-body {
  height: 460px;
}

.circles-container {
  display: flex;
  gap: 8px;
  position: relative;
  justify-content: center;
  top: -10%;
  .circle {
    width: 20px;
    height: 20px;
    background-color: $surface-500;
    border-radius: 50%;

    &.circle-selected {
      background-color: $text-400;
    }
  }
}

// __________________

@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}

ol,
li {
  margin: 0;
  padding: 0;
}

.carousel {
  position: relative;
  perspective: 100px;
  position: static;
}

.carousel__viewport {
  height: 460px;
  display: flex;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow: hidden;
  width: 100vw;
}

.carousel__slide {
  display: flex;
  justify-content: center;
  position: relative;
  flex: 0 0 100%;
  width: 100%;

  &.flex-left {
    justify-content: flex-start;
    .flex-carousel {
      margin-left: 14vw;
    }
  }

  &.flex-right {
    justify-content: flex-end;
    .flex-carousel {
      margin-right: 0vw;
      margin-top: 3rem;
    }
  }
}

.carousel::before,
.carousel__prev {
  left: -1rem;
}

.carousel::after,
.carousel__next {
  right: -1rem;
}

.carousel::before,
.carousel::after {
  content: "";
  z-index: 1;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.brandon-carousel,
.flex-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .carousel-button-contaier {
    width: fit-content;
    display: flex;
  }
}

.p-default {
  display: block;
}

.p-mobile {
  display: none;
}

.flex-carousel {
  h3 {
    font-family: $roboto;
    color: $text-700;
    font-weight: 700;
    margin-bottom: 0;
    padding-top: 1rem;
    word-wrap: break-word;
    width: 95%;
    margin-top: -2rem;
  }

  > p {
    font-family: $merriweather;
    color: $text-400;
    font-size: 1rem;
    width: 32rem;
  }
}

.brandon-carousel {
  margin-left: 30vw;
  width: 50vw;
  min-width: 35rem;

  .brandon-controller {
    font-family: $roboto;
    font-size: 1.2rem;
    margin-top: 0;
    text-transform: uppercase;
    color: $text-700;
    font-weight: 700;
    padding-left: 1rem;
  }
  .brandon-quote {
    color: $secondary-neutral-400;
    font-family: $merriweather;
    font-style: italic;
    margin: 0;
    margin-top: 3rem;
    padding-bottom: 1rem;
  }
}

.button-caption {
  font-family: $merriweather;
  color: $text-400;
  font-size: 1rem;
  width: 26rem;
}

@media screen and (max-width: 1060px) {
  .carousel-body,
  .carousel__viewport {
    height: 350px;
  }

  .brandon-carousel {
    margin-left: 26vw;
    min-width: 27rem;
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .brandon-controller {
      padding-left: 0;
      font-size: 1rem;
    }
    p {
      margin: 0.5rem 0;
    }
  }
  .circles-container {
    .circle {
      width: 12px;
      height: 12px;
    }
  }
}

@media screen and (max-width: 850px) {
  .carousel-body,
  .carousel__viewport {
    height: 280px;
  }
  .brandon-carousel {
    .brandon-quote {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }

  .flex-carousel > p {
    width: 16rem;
  }

  .forth-carousel p {
    width: 30rem;
  }

  .carousel__slide {
    &.flex-left {
      .flex-carousel {
        margin-left: 4rem;
      }
    }
    &.flex-right {
      .flex-carousel {
        margin-right: 4rem;
      }
    }
  }

  .chevron-left {
    padding-left: 12px;
  }

  .chevron-right,
  .chevron-left {
    top: calc(50% - 20px);
    min-width: 50px;
  }

  .carousel__prev,
  .carousel__next {
    svg {
      transform: scale(0.75);
    }
  }

  .carousel::before,
  .carousel::after {
    background-size: 1rem 1rem;
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media screen and (max-width: 750px) {
  .brandon-carousel {
    margin-left: 45vw;
    min-width: 0;
    .button-caption {
      display: none;
    }
    .carousel-button-container {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .carousel-body,
  .carousel__viewport {
    height: 240px;

    .brandon-carousel .brandon-quote {
      padding-bottom: 0;
    }
    .carousel__slide {
      &.carousel-one-background {
        background-position-x: -1rem;
      }
      &.carousel-two-background {
        background-size: 125% 100%;
      }
      &.carousel-three-background {
        background-size: 100% 100%;
      }
    }
  }

  .carousel__slide {
    &.flex-right {
      .flex-carousel {
        margin-right: 1rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .brandon-carousel {
    .brandon-quote {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
    .brandon-controller {
      font-size: 0.8rem;
    }
  }
  .carousel-body,
  .carousel__viewport {
    height: 240px;
  }

  .flex-carousel {
    & > p {
      width: 12rem;
      margin: 0.25rem;
      font-size: 0.8rem;
    }
  }

  .p-default {
    display: none;
  }

  .p-mobile {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .forth-carousel p {
    width: 20rem;
  }
  .carousel-four-background {
    background-image: url("../../../../styles/images/SCOCarouselMobileBanner4.png");
  }
}

@media screen and (max-width: 420px) {
  .brandon-carousel {
    .brandon-quote {
      font-size: 0.6rem;
      line-height: 1rem;
      margin-top: 2rem;
    }
    .brandon-controller {
      font-size: 0.6rem;
    }
  }
  .carousel-body,
  .carousel__viewport {
    height: 190px;
  }

  .flex-carousel {
    & > p {
      width: 10rem;
      margin: 0.25rem;
      font-size: 0.7rem;
    }
  }

  .forth-carousel p {
    width: 14rem;
    margin: 0rem;
    font-size: 0.7rem;
  }
}
