@use '../../../../styles/' as *;

.county-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33%;
  padding: .25rem;
  font-weight: 300;
  font-family: $merriweather;
  color: $text-500;
  cursor: pointer;
  white-space: nowrap;
}

.county-item:hover {
  color: $text-600;
  text-decoration: underline;
}

.seal-image {
  padding-right: .5rem;
  height: 2.5rem;
}