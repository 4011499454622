@use "../../../styles/" as *;

.ld-dropdown-container {
  display: flex;
  gap: 56px;
  margin: 2rem 0rem;
}

.tab-content-inactive {
  display: none;
}

.tab-content-active {
  display: block;
}

.dropdown-header {
  font-family: $merriweather;
  text-transform: uppercase;
  font-weight: 400;
  color: $text-500;
  font-size: 1.5rem;
}

.local-district-image-section img {
  border-radius: 0.75rem;
  height: 40vw;
  width: 100%;
  @media (min-width: 768px) {
    width: 33rem;
    height: 21rem;
  }
}

.entity_heading {
  margin: 1rem;
  font-family: "Roboto Slab", serif;
}

.narrative-tab-container {
  margin-right: 2rem;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-size: cover;
  background-color: #ededeb;
}

.narrative-section {
  padding: 4rem 4rem 4rem 4rem;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  &__details {
    line-height: 1.5rem;
    font-family: $merriweather;
    font-size: 1.125rem;
    width: 92%;
  }
  &__heading {
    font-family: $roboto;
    margin-bottom: 1rem;
  }
  &__picture {
    flex: 1;
    & img {
      border-radius: 0.625rem;
      max-height: 20rem;
      width: 33.875rem;
      height: 20rem;
      object-fit: cover;
      object-position: center;
    }
  }
}

.report-section {
  &__tabsContainer {
    margin-right: 2rem;
    display: flex;
    gap: 10px;
    background-color: #ededeb;
    padding-left: 2rem;
    background-size: cover;
    background-position: center;
  }

  &__tab {
    color: $text-100;
    background-color: $primary-neutral-400;
    padding: 8px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    & h1 {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    &--selected {
      color: $text-100;
      background-color: $primary-500;
    }
  }

  &__detailContainer {
    padding-left: 2rem;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
    background-color: $primary-500;
    margin: 0 2rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__heading {
    color: $text-100;
  }

  &__details {
    line-height: 1.6;
    color: $text-100;
  }
}

.localDistrictReportContainer {
  padding-bottom: 1rem;
  margin-right: 2rem;
  background-color: $primary-500;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media screen and (max-width: $desktop-min) {
  .narrative-section {
    flex-direction: column;
    padding: 1rem 4rem;
    &__picture {
      margin: 2rem 0;

      & img {
        object-position: center;
        object-fit: cover;
        border-radius: 1rem;
        height: 8.875rem;
        border-radius: 0.28rem;
      }
    }
  }

  .report-section {
    &__tabsContainer {
      display: none;
    }
    &__detailContainer {
      padding-left: 2rem;
    }
  }

  .ld-dropdown-container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: $tablet-min) {
  .search-entity-section {
    flex-direction: column;
    padding-bottom: 0rem;
  }

  .narrative-section {
    padding: 2rem 1rem;

    &__heading {
      padding-bottom: 10px;
      border-bottom: 1px solid $primary-500;
      font-family: $merriweather;
      color: $text-700;
      margin-bottom: 1rem;
      font-weight: 400;
    }
    &__details {
      font-size: 0.875rem;
      width: 100%;
    }
  }

  .report-section {
    &__tabsContainer {
      display: none;
    }
    &__detailContainer {
      padding-left: 2rem;
      flex-direction: column;
    }
    &__report {
      flex-basis: auto;
    }
  }

  .ld-dropdown-container {
    display: flex;
    flex-direction: column;
  }
}
