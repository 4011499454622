@use "../../../../styles/" as *;

.hamburger-menu {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  background-color: $primary-400;
  border: none;
  padding: 0.55rem 0.45rem 0.3rem 0.45rem;
  border-radius: 50%;
  z-index: 100;
}

.hamburger-menu[aria-expanded="true"] {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.hamburger-menu .line {
  fill: $primary-neutral-100;
  transition: transform 300ms ease-in 300ms, rotate 300ms ease-in,
    opacity 0ms 300ms;
  transform-origin: center;
}

.hamburger-menu[aria-expanded="true"] .line {
  transition: transform 300ms ease-in, rotate 300ms ease-in 300ms,
    opacity 0ms 300ms;
}

.hamburger-menu[aria-expanded="true"] .top {
  transform: translateY(25px);
}

.hamburger-menu[aria-expanded="true"] .bottom {
  transform: translateY(-25px);
}

.hamburger-menu[aria-expanded="true"] .top {
  rotate: 45deg;
}

.hamburger-menu[aria-expanded="true"] .middle {
  opacity: 0;
}

.hamburger-menu[aria-expanded="true"] .bottom {
  rotate: -45deg;
}

.hamburger-menu__dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 4.625rem 1rem 1rem;
  top: 0;
  right: 0;
  width: 15rem;
  height: 20rem;
  z-index: 99;
  transition: all 300ms ease-in;
  transform-origin: top;
  border-radius: 0px 0px 0px 0.625rem;
}

.hamburger-menu__dropdown:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../../../styles/images/SCOMenuTextureGraphic.webp");
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 15%;
  z-index: -1;
  border-radius: 0px 0px 0px 0.625rem;
}

.hamburger-menu__dropdown:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-color: $surface-200;
  left: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -2;
  border-radius: 0px 0px 0px 0.625rem;
}

.hamburger-menu__dropdown[aria-expanded="false"] {
  transform: scaleY(0);
}

.link {
  justify-content: center;
  text-decoration: none;
  font-weight: 700;
  font-family: $merriweather;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  background-color: transparent;
  line-height: 36px;
}

.inactive-link {
  color: $text-600;
}

.inactive-link:hover {
  padding-left: 0.75rem;
}

.active-link {
  padding-left: 0.75rem;
  color: $text-700;
  border-bottom: 2px solid $primary-neutral-400;
}

.back-to-link {
  display: flex;
  direction: row;
  align-items: center;
  margin-top: 1rem;
  transform: translateX(-0.75rem);
  cursor: pointer;
}

.back-to-link a {
  text-decoration: none;
  font-family: $merriweather;
  color: $text-400;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
