@use "../../styles/" as *;

.home-container {
  display: flex;
  flex-direction: column;
  background-color: #fcfdf8;
}

.home-page-title-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: $text-700;

  .title {
    font-family: "Roboto Slab";
  }
}

.home-page-header-description {
  color: $text-400;
  max-width: 29rem;
  font-family: "Merriweather Sans";
}

.header-title {
  background-color: $surface-200;
  color: $text-700;
  font-family: $roboto;
}

.header-description {
  color: $text-400;
  font-family: $merriweather;
  white-space: pre-wrap;
}

.explore-data-button {
  background-image: $graphic-700;
  color: $primary-100;
}

.vertical-cards-section-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $surface-100;
}

.cards-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.read-more-button {
  color: $text-100;
  font-family: "Merriweather Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  cursor: pointer;
}

.about-description {
  color: $text-100;
  font-family: "Merriweather Sans";
}

@media screen and (max-width: 1220px) {
  .header-title {
    padding-left: 1.1875rem;
  }

  .header-description {
    padding-left: 1.1875rem;
  }

  .cards-section-wrapper {
    padding-top: 2.6rem;
  }
}

@media screen and (max-width: 830px) {
  .header-title {
    font-size: 2rem;
  }

  .cards-section-wrapper {
    margin: 0 2rem;
  }
}

@media screen and (max-width: 845px) and (max-height: 400px) {
  .display-none {
    display: none;
  }
}
