@use "../../../../styles/" as *;

.mega-search-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 6.9rem;
  width: fit-content;
  right: 2rem;
  border-radius: 0 0 1.25rem 1.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 13;
  transition: opacity 300ms ease-in-out;
  transform-origin: top;
  padding: 2rem;
}

.mega-search-container:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 1.25rem 1.25rem;
  background-image: url("../../../../styles/images/SCOMenuTextureGraphic.webp");
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 15%;
  z-index: 9;
}

.mega-search-container:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $surface-200;
  border-radius: 0 0 1.25rem 1.25rem;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 8;
}

.hide {
  transform: scaleY(0);
  opacity: 0;
}

.menu-header {
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid $primary-neutral-400;
  margin-bottom: 1rem;
  z-index: 12;
}

.menu-header-title {
  font-family: $merriweather;
  color: $text-700;
  font-weight: normal;
  padding-bottom: 0.75rem;
  margin: 0;
}

.menu-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  z-index: 12;
}

.item-list {
  display: grid;
  height: 20.8rem;
  width: fit-content;
  gap: 0.75rem;
  grid-template-columns: repeat(3, 0.3fr);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.item-list::-webkit-scrollbar {
  width: 0.75rem;
  background-color: $scrollbar-track;
  border-radius: 0.5rem;
}

.item-list::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb;
  border-radius: 1rem;
}

.simple-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid black;
  padding: 1rem 0 1rem 2rem;
  gap: 1rem;
  .simple-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .svg-icon {
      width: 2rem;
    }
    .link {
      color: $text-500;
      font-family: Merriweather Sans;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
      margin: 0;
      max-width: 20rem;

      &:hover {
        color: $text-600;
      }
    }
  }
}

@media screen and (max-width: 1275px) {
  .mega-search-container {
    width: fit-content;
  }
  .menu-body {
    gap: 1.5rem;
  }
  .item-list {
    gap: 1rem;
    .county-item {
      font-size: small;
    }
  }
}

@media screen and (max-width: 1200px) {
  .simple-links-container {
    .simple-link {
      .link {
        font-size: 1rem;
        max-width: 12rem;
      }
    }
  }
}
