@use "../../styles/" as *;

.info-bubble-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
  text-align: center;
  min-width: 0;
  max-width: 100%;
  flex: 1 0 0;
}

.info-bubble-button {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
}

.info-bubble-button-gradient {
  background: linear-gradient(
    138.48deg,
    rgba(255, 255, 255, 0.6) 14.74%,
    rgba(255, 255, 255, 0.2) 86.89%
  );
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.info-bubble-button-solid {
  background: rgba(62, 94, 148, 0.6);
  border: 1px solid rgba(62, 94, 148, 0.2);
}

.info-bubble-button-landing-page {
  content: "";
  width: 8rem;
  border-radius: 50%;
  background-image: url("../../styles/images/SCODashboardMedallion.png");
  height: 8rem;
  background-size: contain;
}

.info-bubble-amount-text {
  display: flex;
  position: absolute;
  top: 3.5rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: $text-100;
  font-family: $merriweather;
  white-space: pre-line;
  margin: 0;
}

.info-bubble-bottom {
  font-family: $merriweather;
  text-transform: uppercase;
}

.info-bubbles-bottom-headers {
  font-family: $merriweather;
  padding-top: 1rem;
}

.info-bubbles-bottom-header {
  margin: 0;
  font-weight: normal;
  text-transform: uppercase;
  color: $text-700;
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
  max-width: 12rem;
  font-size: 1.2rem;
}
.info-bubbles-bottom-subheader {
  text-transform: capitalize;
  color: $text-700;
}

.info-bubble-text-dark {
  color: $text-400;
}

.info-bubble-text-light {
  color: $text-100;
}

.info-bubble-title-container {
  height: 1.45rem;
}

.info-bubble-title-image {
  margin-top: 0.5rem;
  scale: 1.1;
}

.info-bubble-icon {
  display: flex;
  position: absolute;
  height: 2rem;
  z-index: 3;
  top: 1rem;
  color: white;
}

@media screen and (max-width: 1500px) {
  .info-bubble-title-image {
    width: 80%;
  }

  .info-bubble-button {
    width: 7rem;
    height: 7rem;
  }

  .info-bubble-button-landing-page {
    content: "";
    width: 7rem;
    border-radius: 50%;
    background-image: url("../../styles/images/SCODashboardMedallion.png");
    height: 7rem;
  }

  .info-bubble-icon {
    top: 0.5rem;
  }

  .info-bubble-amount-text {
    top: 3rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .info-bubble-title {
    font-size: 1.1rem;
  }

  .info-bubble-bottom {
    font-size: 1.1rem;
    line-height: 18px;
  }

  .info-bubble-container {
    width: 12rem;
  }
}

@media screen and (max-width: 500px) {
  .state .info-bubble-button-solid {
    background: linear-gradient(
      138.48deg,
      rgba(255, 255, 255, 0.6) 14.74%,
      rgba(255, 255, 255, 0.2) 86.89%
    );
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .info-bubbles-bottom-headers {
    padding: 0;
  }
}
