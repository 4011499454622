@use "../../styles/" as *;

.power-bi-container {
  width: 820px;
  height: 556px;
  padding: 1 !important;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #f2f2f0;
  position: relative;
  .placeholder{
    position: absolute;
    z-index: 2;
    filter: blur(5px);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .loader {
    position: absolute;
    height: 260px;
    width: 260px;
    inset: 0;
    margin: auto;
    z-index: 3;
  }
}

.power-bi-container {
  &.full-screen-report {
    width: 100%;
    max-width: 80rem;
    height: auto;
    max-height: 60rem;
    aspect-ratio: 16 / 9;

    @media (max-width: 600px) {
      max-width: 100%;
      max-height: none;
    }
  }
}
.publish-to-web-clip-path {
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 60px),
    0% calc(100% - 60px)
  );
}

@media screen and (max-width: 900px) {
  .power-bi-container {
    width: 100%;
    height: 55vw;
  }
}

@media screen and (max-width: 1200px) {
  .powerBiContainer {
    width: 90vw;
    height: 55vw;
    padding: 0 !important;
  }
}
