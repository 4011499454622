@use "../../../styles/" as *;
.commonly-requested-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  padding-top: 4.62rem;
  padding-bottom: 4.62rem;
  h4 {
    font-family: Roboto Slab;
    color: $text-700;
  }
  p {
    max-width: 50rem;
    padding-top: 0.5rem;
    font-family: Merriweather Sans;

    color: $text-400;
  }

  .table-padding {
    padding-bottom: 5.5rem;
  }
}

.commonly-request-tables {
  padding-bottom: 5.5rem;
}
