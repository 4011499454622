@use '../../styles/' as *;

.button-container {
  border-radius: 6px;
  background: $graphic-700;
  color: $text-100;
  cursor: pointer;
  padding: 0.4rem 1.6rem 0.4rem 1.6rem;
  font-family: $roboto;
  box-shadow: $elevation-2;
  transition: box-shadow 0.3s ease-in-out;
  border: 2px solid #2A4E80;
  white-space: nowrap;
  display: flex;
}

.button-container-menu {
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: $graphic-700;
  color: $text-100;
  border-radius: .5rem;
  padding: 0.4rem 1.6rem 0.4rem 1.6rem;
  border: 4px solid #2A4E80;
  font-family: $roboto;
  transition: box-shadow 0.3s ease-in-out;
  white-space: nowrap;
}

.button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

#button:hover {
  box-shadow: $elevation-4;
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}