@use "../../styles/" as *;

.picture-idaho-background {
  background-image: url("../../styles/images/SCOCountylandingpageIdaho.webp");
  background-repeat: no-repeat;
  background-position: top;
  background-position-x: right;
  background-size: 67%;
}

.counties-info-bubbles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 56.25rem;
  white-space: pre-line;
  padding-top: 6.5rem;
}

.icon-bubble {
  color: $text-100;
  padding-bottom: 0.1rem;
  padding-top: 1.2rem;
}

.icon-bubble-dollar {
  color: $text-100;
  padding-bottom: 0.1rem;
  padding-top: 1rem;
}

.explore-idaho-button {
  display: flex;
  position: absolute;
  right: 5%;
  top: 0.05%;
  z-index: 10;
}

.hover-county-text {
  display: flex;
  position: absolute;
  top: 45%;
  left: 60%;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.more-high-level-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;

  &.blue {
    background-color: $surface-200;
  }
}

.more-high-level-reports-section {
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
  &.blue {
    background-color: $surface-200;
  }
}

.more-high-level-cards-header {
  color: $text-700;
  font-weight: 700;
  font-family: $roboto;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

.more-high-level-cards-sub-header {
  color: $text-600;
  font-family: $merriweather;
  padding-bottom: 0.875rem;
  padding-left: 1rem;
  font-weight: 400;
  font-family: $merriweather;
  color: $text-400;
}

.more-high-level-cards {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-bottom: 1rem;
  -webkit-overflow-scrolling: touch;
}

.more-high-level-cards::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.3rem;
  background-color: $scrollbar-track;
}

.more-high-level-cards::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb;
  border-radius: 1rem;
}

.more-high-level-cards::-webkit-scrollbar-track {
  border-radius: 50%;
}

.counties-nav-list-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $surface-200;
  padding-top: 4.5rem;
  padding-left: 5.3125rem;
}

.counties-nav-list-data-header {
  padding-bottom: 0.5rem;
  color: $text-700;
  font-family: $roboto;
}

.counties-nav-list-data-sub-header {
  font-family: $merriweather;
  padding-bottom: 2.25rem;
  white-space: pre-line;
  color: $text-400;
}

.counties-nav-list {
  display: grid;
  padding-bottom: 4.5rem;
  grid-template-columns: 25% 25% 25% 25%;
  max-width: 70vw;
  height: 50rem;
}

@media screen and (min-width: 1550px) {
  .picture-idaho-background {
    background-size: 65rem;
  }

  .explore-idaho-button {
    left: 1090px;
  }
}

@media screen and (max-width: 1560px) {
  .counties-nav-list {
    max-width: 80vw;
  }
}

@media screen and (max-width: 1400px) {
  .counties-nav-list {
    overflow-x: auto;
    max-width: 100vw;
    -webkit-overflow-scrolling: touch;
  }

  .counties-nav-list::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.7rem;
    background-color: $scrollbar-track;
  }

  .counties-nav-list::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb;
    border-radius: 1rem;
  }

  .counties-nav-list::-webkit-scrollbar-track {
    border-radius: 50%;
  }
}

@media screen and (max-width: 1350px) {
  .explore-idaho-button {
    right: 4%;
  }

  // .picture-idaho-background {
  //   background-size: 58.5%;
  // }
}

@media screen and (max-width: 1300px) {
  .explore-idaho-button {
    scale: 0.8;
    right: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .icon-bubble {
    color: $text-100;
    padding-bottom: 0.1rem;
    padding-top: 0.3rem;
  }

  .icon-bubble-dollar {
    color: $text-100;
    padding-bottom: 0.1rem;
    padding-top: 0.4rem;
    height: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .explore-idaho-button {
    right: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .more-high-level-cards {
    width: 90vw;
    overflow-x: auto;
    margin: 1rem 0;
  }

  .explore-idaho-button {
    display: none;
  }

  .picture-idaho-background {
    background-image: url("../../styles/images/../../styles/images/backgroundCutout2.webp");
    // background-size: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .icon-bubble {
    color: $text-100;
    padding-bottom: 0.1rem;
    height: 1.7rem;
  }

  .icon-bubble-dollar {
    color: $text-100;
    padding-bottom: 0.1rem;
    height: 2rem;
  }

  .counties-nav-list {
    column-gap: 5rem;
  }
}

@media screen and (max-width: 960px) {
  .picture-idaho-background {
    background-size: 63%;
  }
}

@media screen and (max-width: 800px) {
  .explore-idaho-button {
    display: none;
  }

  .counties-info-bubbles {
    width: 80vw;
  }
}

@media screen and (max-width: 700px) {
  .counties-nav-list-section {
    padding-left: 2rem;
  }

  .picture-idaho-background {
    background-size: 62.5%;
  }
}

@media screen and (max-width: 600px) {
  .counties-nav-list {
    column-gap: 7rem;
  }

  .counties-nav-list-data-sub-header {
    white-space: normal;
  }
}

@media screen and (max-width: 520px) {
  .picture-idaho-background {
    background-size: 70.5%;
  }
}

@media screen and (max-width: 450px) {
  .picture-idaho-background {
    background-size: 75%;
  }
}

@media screen and (max-width: 400px) {
  .more-high-level-cards {
    gap: 1rem;
  }

  .picture-idaho-background {
    background-size: 72%;
  }
}

@media screen and (max-width: 350px) {
  .counties-info-bubbles {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .counties-nav-list {
    column-gap: 8rem;
  }
}
