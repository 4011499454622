@use "../../../styles/" as *;

.state-budget-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 4.5rem;
  z-index: 1;
  gap: 3rem;

  @media screen and (max-width: 900px) {
    display: none;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2 {
      font-family: Roboto Slab;
    }
    p {
      font-family: Merriweather Sans;
      max-width: 75rem;
    }
  }

  .budget-options-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.44rem;
    border-radius: 0.5rem;
    border: 1px solid $primary-500;
    background: $text-100;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    button {
      background-color: #00000000;
      border: none;
      padding: 0.25rem, 0.69rem;
      font-family: Merriweather Sans;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      border-radius: 0.375rem;
      color: $text-400;
      border: 1px solid #00000000;
      cursor: pointer;

      &:hover {
        border: 1px solid $blue-100;
        background: rgba(240, 243, 244, 0.54);
      }
      &[aria-selected="true"] {
        border: 1px solid $blue-100;
        background: rgba(240, 243, 244, 0.54);
      }
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../styles/images/SCOMenuTextureGraphic.webp");
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    z-index: -1;
  }
}

.budget-content-container {
  display: flex;
  gap: 1.75rem;
  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }

  img {
    width: 40rem;
    height: 26rem;
    border-radius: 1rem;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: Merriweather Sans;
    max-width: 40rem;
    @media screen and (max-width: 1200px) {
      max-width: fit-content;
    }
  }

  .tag-container {
    width: fit-content;
    padding: 0.25rem 0.75rem;
    justify-content: center;
    align-items: flex-start;
    gap: 0.625rem;
    background-color: $green-300;
    border-radius: 5.63rem;
  }
}
