@use "../../styles/" as *;
.state-landing-page-hero-image {
  display: flex;
  position: absolute;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 49.4375rem;
  z-index: -1;
  bottom: 0;
  left: 0;
}

.covid-description-container {
  position: relative;
}
