@use "../../styles/" as *;

.search-section-ld {
  display: flex;
  flex-direction: column;
  padding-top: 4.5rem;
  background-color: $surface-200;
  z-index: 0;
  padding-left: 4rem;
  padding-right: 1rem;
  padding-bottom: 4.5rem;
}

.local-district-search-section {
  display: flex;
  flex-direction: column;
  padding-top: 4.5rem;
  background-color: $surface-100;
  z-index: 0;
  padding-left: 4rem;
  padding-right: 1rem;
  padding-bottom: 4.5rem;
}

.ld-entity-search-subheader {
  font-family: $merriweather;
  text-transform: uppercase;
  font-weight: 400;
  color: $text-500;
  padding-bottom: 0.75rem;
}

.districts-entity-search-header {
  margin-top: 0;
  color: $text-700;
  font-family: $roboto;
  font-weight: 700;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
}

.districts-entity-search-subheader {
  font-family: $merriweather;
  width: 80%;
  color: $text-400;
  font-weight: normal;
  white-space: pre-line;
  padding-bottom: 1rem;
}

.districts-entity-search-menu-header {
  font-family: $merriweather;
  text-transform: uppercase;
  color: $text-500;
  padding-bottom: 0.75rem;
}

.ld-download-pdfs-section {
  padding-top: 4.5rem;
  background-color: $surface-100;
  padding-bottom: 3rem;
  padding-left: 6rem;
}

.urban-renewal-header {
  color: $text-700;
  font-family: $roboto;
  padding-bottom: 0.5rem;
  font-size: xx-large;
  font-weight: 700;
}

.urban-renewal-subheader {
  font-family: $merriweather;
  padding-bottom: 1rem;
  color: $text-400;
  white-space: pre-line;
  width: 40%;
}

.ld-downloads-list-container {
  display: grid;
  grid-template-columns: repeat(4, 0.23fr);
  gap: 1.2rem;
  overflow: auto;
  .no-reports-message {
    color: $text-400;
    font-family: $merriweather;
  }
}

.dual-search-container {
  display: flex;
  gap: 56px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) {
  .search-section-ld {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 4.5rem;
  }

  .local-district-search-section {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 4.5rem;
  }

  .ld-entity-search-subheader {
    padding-bottom: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .dual-search-container {
    gap: 1rem;
  }

  .districts-entity-search-subheader {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 800px) {
  .search-section-ld {
    padding-left: 2rem;
  }
  .local-district-search-section {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .urban-renewal-subheader {
    width: 70vw;
  }

  .districts-entity-search-subheader {
    width: 70vw;
  }

  .ld-download-pdfs-section {
    padding-left: 1rem;
  }

  .dual-search-container {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .search-section-ld {
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
    padding-bottom: 4.5rem;
  }

  .local-district-search-section {
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
    padding-bottom: 4.5rem;
  }
}
