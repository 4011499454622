@use '../../../../styles/' as *;

.breadcrumbs-container {
  display: flex;
  margin-left: 5rem;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.breadcrumbs-text {
  margin-top: 0;
  margin-bottom: 0;
  color: $primary-neutral-400;
  padding-left: 0.2rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  font-family: $merriweather;
}

.house-icon {
  padding-right: 0.4rem;
}

@media screen and (max-width: 959px) {
  .breadcrumbs-container {
    display: none;
  }
}