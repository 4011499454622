@use '../../styles/' as *;

.not-found-page {
    background-color: $surface-100;
    height: 60vh;
}

.not-found-page-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    background-image: url("../../styles/images/errorPageImages/SCO404PageBackgroundGraphic.png");
    background-repeat: no-repeat;
    background-position: center right;
    margin-right: 5rem;
    padding-left: 7.3125rem;
    background-size: 75vw;
    background-color: $surface-100;
}

.not-found-page-text {
    white-space: pre-line;
    padding-bottom: 1rem;
}

.not-found-page-text>h1 {
    color: $primary-500;
    font-weight: bold;
    font-family: $roboto;
    padding-bottom: 1rem;
}

.not-found-page-text>h6 {
    color: $primary-500;
    font-family: $merriweather;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .not-found-page-container {
        padding-left: 2rem;
    }
}