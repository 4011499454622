@use "../../styles/" as *;

.tag-pill {
  font-weight: 500;
  display: inline-block;
  color: $blue-300;
  padding: 4px 12px;
  border-radius: 90px;
  margin-left: 1rem;
  font-weight: 500;
  border: solid 2px;
  background-color: $surface-100;
  cursor: pointer;
}

.tag-pill-filled {
  font-weight: 500;
  display: inline-block;
  color: $text-100;
  padding: 4px 12px;
  border-radius: 90px;
  font-weight: 500;
  border: solid 2px;
  background-color: $primary-400;
  border-color: $blue-300;
  cursor: pointer;
}
