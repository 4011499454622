body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #c0c2c2;
}

:root .uwy {
  position: relative !important;
  z-index: 20;
}

@media screen and (min-width: 1920px) {
  :root body .uwy .userway_buttons_wrapper {
    /* calculate out anything greater than 1920px */
    left: calc(50% - 947px) !important;
  }

  .uwaw {
    position: absolute !important;
    left: 45vw;
  }
}

:root .uwy.userway_p7 .uai,
body .uwy.userway_p8 .uai {
  position: absolute !important;
}

body .uwy .userway_buttons_wrapper {
  position: absolute !important;
  margin-left: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  border: none;
}
