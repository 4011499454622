@use '../../styles/' as *;

.county-section {
  fill: #3E5E94A6;
  stroke-width: .105rem;
  stroke-linejoin: bevel;
  stroke: $primary-neutral-100;
}

.county-section:hover {
  fill: $primary-200;
  opacity: .65;
  stroke-width: .25rem;
  stroke-dashoffset: 10rem;
}

.active-county-section {
  fill: $primary-100;
  opacity: .65;
  stroke-width: .25rem;
  stroke-dashoffset: 10rem;
  stroke: $primary-neutral-100;
}

.idaho-container {
  min-width: 23.3rem;
  max-width: 23.3rem;
  min-height: 35.07rem;
  max-height: 35.07rem;
  rotate: -12.5deg;
}

.tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  text-align: center;
  position: fixed;
  width: 104px;
  height: 72px;
  z-index: 99999;
  background-color: #5d6063;
  border-radius: .5rem;
  border: 1px solid black;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  transition:
    opacity 500ms ease-in-out;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: -9.3px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #5d6063;
  z-index: 100;
  pointer-events: none;
}

.hide {
  opacity: 0;
}

.tooltip__title {
  font-size: 1rem;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-top: .5rem;
  font-family: $merriweather;
}

.tooltip__text {
  font-size: 0.625rem;
  color: white;
  font-family: $merriweather;
}

@media screen and (max-width: 1000px) {
  .idaho-container {
    min-width: 18rem;
    max-width: 18rem;
    min-height: 25rem;
    max-height: 25rem;
  }
}