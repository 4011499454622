@use "../../styles/" as *;

.report-data-section {
  display: flex;
  padding-top: 4.5rem;
  gap: 1.75rem;
}

.report-text-container {
  width: 30vw;
  max-width: 600px;
}

.report-data-header {
  color: $text-600;
  white-space: pre-line;
  font-family: $merriweather;
  padding-bottom: 1.5rem;
  font-size: 2.25rem;
}

.report-data-text {
  color: $text-500;
  font-family: $merriweather;
  white-space: pre-line;
  padding-right: 2rem;
  max-height: 24.25rem;
  -webkit-overflow-scrolling: touch;
}

.report-data-text::-webkit-scrollbar {
  width: 0.75rem;
  background-color: $scrollbar-track;
  border-radius: 1rem;
}

.report-data-text::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb;
  border-radius: 1rem;
}

.data-source-text {
  font-family: $merriweather;
  font-size: 1rem;
  color: $text-300;
}

.keep-reading-more-button {
  font-family: $merriweather;
  font-weight: 700;
  color: $primary-500;
  text-decoration: underline;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.coming-soon-container {
  display: flex;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #f2f2f0;
}

.coming-soon-report-image {
  width: 884px;
  // height: 496px;
  border-radius: 0.5rem;
}

.coming-soon-button {
  display: flex;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: calc(50% - 5.5rem);
  font-family: $merriweather;
  font-weight: 300;
  color: $blue-300;
  font-size: 1.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 90px;
  line-height: 40px;
  background-color: white;
  border: 2px solid $blue-300;
}

.download-report-data {
  font-family: $merriweather;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: $text-300;
  padding-left: 0.75rem;
  letter-spacing: 0.4px;
}

.alt-download-link {
  display: flex;
  align-items: center;
  color: $primary-400;
  width: fit-content;
}

.alt-download-link:hover {
  cursor: pointer;
  color: $primary-300;
}

.alt-download-report-data-text {
  font-family: $merriweather;
  font-weight: bold;
  font-size: 12px;
  line-height: 1rem;
  color: inherit;
  text-decoration: underline;
  padding-left: 0.75rem;
  letter-spacing: 0.4px;
}

.bold-text {
  font-weight: 700;
  padding-right: 0.1rem;
}

.download-data-container {
  display: flex;
  align-items: center;
  justify-content: end;
}

@media screen and (min-width: 1600px) {
  .report-data-section {
    gap: 7.8125rem;
  }
}

@media screen and (max-width: 1200px) {
  .coming-soon-report-image {
    width: 90vw;
    height: 55vw;
    padding: 0;
  }

  .report-data-section {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 10rem;
  }

  .report-data-section-blue {
    flex-direction: column;
    height: 730px;
  }

  .report-text-container {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
    max-width: none;
  }

  .report-data-text {
    max-height: 18rem;
  }

  .report-powerBi-container {
    padding-right: 0;
    margin-right: 0;
  }
}
iframe {
  width: 884px;
  height: 556px;
}

.iframe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 620px;

  h6 {
    padding: 1rem;
  }
}

.reportMobileNav {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid white;
  gap: 5px;

  &__text {
    color: $text-100;
    font-size: 1.25rem;
    font-family: $merriweather;
  }
  &__iconContainer {
    border-radius: 100px;
    min-width: 3rem;
    min-height: 3rem;
    background-color: white;
    & svg {
      color: $primary-500;
    }
  }
}

.report-blank-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-bottom: 1rem;

  & span {
    color: $text-100;
  }
}

.report-note {
  & span {
    font-weight: bold;
  }
  color: $text-100;
}

@media screen and (max-width: 1200px) {
  iframe {
    width: 90vw;
  }
  .report-note {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .coming-soon-report-image {
    width: 80vw;
    height: 45vw;
    padding: 0;
  }

  .coming-soon-button {
    font-size: 1.25rem;
    top: calc(50% - 1.5rem);
    left: calc(50% - 4.5rem);
  }
}

@media screen and (max-width: $desktop-min) {
  .reportMobileNav {
    display: flex;
    text-align: center;
  }
}
